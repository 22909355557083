import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div>
        <h4 className="aboutUs">
          Firma „KENO-EKO” działa na rynku nieprzerwanie od 1993 roku.
          Założycielami firmy są Jan Ciszewski i Jan Pękala. Od tego czasu udało
          nam się stworzyć stabilną markę na rynku regionalnym oraz wykreować
          wizerunek godnego zaufania partnera wśród lokalnej społeczności.
          Obecnie współwłaścicielami firmy są Jarosław Ciszewski i Marcin
          Pawelski, którzy kontynuują 30-letnią tradycję.
          <br />
          <br />
          <h2 style={{ paddingBottom: "100px", textAlign: "center" }}>
            Zapraszamy do współpracy!
          </h2>
        </h4>
      </div>
    </>
  );
};

export default AboutUs;
