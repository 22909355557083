import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation(); // Lokalizacja trasy

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // Resetuj przewijanie przy zmianie ścieżki

  return null; // ScrollToTop nie renderuje niczego
}

export default ScrollToTop;
