import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import Gallery from "./components/Gallery";
import Navigation from "./components/Navigation";
import Kontakt from "./components/Kontakt";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Navigation />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/galeria/:galleryId" element={<Gallery />} />
          <Route path="/galeria" element={<Gallery />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/o-nas" element={<AboutUs />} />
          <Route path="/usługi" element={<Services />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
