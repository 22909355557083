import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./NavigationBottom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdPeopleAlt } from "react-icons/md";
import { FaBuildingUser } from "react-icons/fa6";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GrGallery } from "react-icons/gr";

const NavigationBottom = () => {
  return (
    <nav className="bottom-nav fixed-bottom">
      <Link to="/o-nas" className="nav-item">
        <MdPeopleAlt className="icon" />O nas
      </Link>
      <Link to="/usługi" className="nav-item">
        <FaBuildingUser className="icon" />
        Usługi
      </Link>
      <Link to="/kontakt" className="nav-item">
        <FaMapMarkerAlt className="icon" />
        Kontakt
      </Link>
      <Link to="/" className="nav-item">
        <GrGallery className="icon" />
        Galeria
      </Link>
    </nav>
  );
};

export default NavigationBottom;
