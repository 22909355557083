import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./Kontakt.css";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const Kontakt = () => {
  const position = [52.251672985230606, 15.925026083431819];

  const markerIcon = new L.Icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <>
      <div style={{ paddingTop: "103px" }}>
        <h2 style={{ textAlign: "center" }}>Kontakt</h2>
        <div style={{ textAlign: "center" }}>
          Siedziba biura: <br />
          ul. Przedmieście Św. Wojciech 21/1 <br />
          64-360 Zbąszyń <br />
          tel. kontaktowy: 68 384 75 43
          <br />
          <br />
        </div>
        <p style={{ textAlign: "center" }}>
          Siedziba firmy: <br /> „Keno-eko” sp.j. Jarosław Ciszewski, Marcin
          Pawelski <br />
          ul. 17 Stycznia nr 9 <br /> 64-360 Zbąszyń <br />
          <br />
          <strong>kenoekobiuro@gmail.com</strong>
        </p>
      </div>
      <MapContainer
        center={position}
        zoom={16}
        style={{ height: "600px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} icon={markerIcon}>
          <Popup>Keno-Eko</Popup>
        </Marker>
      </MapContainer>
    </>
  );
};

export default Kontakt;
