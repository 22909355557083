import React, { useState } from "react";

import { Container, Navbar, Nav, Col, Row } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import logo from "./../images/logo2.png";

import "./Navigation.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBottom from "./NavigationBottom";

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      className="main-container fixed-top"
    >
      <Container fluid className="top-container">
        <Row className="logo-and-phone justify-content-between style">
          <Col sm={6}>
            <Link to="/">
              <img className="logo-style" src={logo} alt="logo" />
            </Link>
          </Col>
          <Col sm={6} className="d-flex justify-content-end align-items-center">
            {/* <button className="phone-button">
              <FaPhoneAlt
                style={{
                  color: "#4dc06f",
                  marginTop: "-1px",
                  marginRight: "5px",
                }}
              />
              <span className="phone-number">68 3847 543</span> */}
            <button className="phone-button">
              <a href="tel:+48683847543" className="phone-number">
                <FaPhoneAlt className="phone-icon" />
                68 3847 543
              </a>
            </button>
          </Col>
        </Row>
        <Row>
          <Col className="mr-0">
            <Navbar.Toggle
              aria-controls="navbarNav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            />

            <Navbar.Collapse id="navbarNav">
              <Nav
                className="ml-auto mr-0 nav-elements"
                onClick={() => setExpanded(false)}
              >
                <Link to="/o-nas" className="nav-link top-nav">
                  o nas
                </Link>
                <Link to="/usługi" className="nav-link top-nav">
                  Usługi
                </Link>
                <Link to="/kontakt" className="nav-link top-nav">
                  Kontakt
                </Link>

                <Link to="/" className="nav-link top-nav">
                  Galeria
                </Link>
              </Nav>
            </Navbar.Collapse>

            <NavigationBottom />
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Navigation;
