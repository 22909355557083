import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <>
      <h4
        style={{
          marginTop: "103px",
          marginLeft: "30px",
          marginRight: "30px",
          marginBottom: "100px",
          fontWeight: "400",
        }}
      >
        Przedmiotem działalności firmy jest szeroko pojęta budowa:
        <br />
        <ul>
          <li>dróg, chodników, placów, dróg dojścia itp.</li>

          <li>kanalizacji deszczowej i sanitarnej,</li>
          <li>odwodnień i prac drenarskich,</li>
          <li>wodociągów i przyłączy wodociągowych,</li>
          <li>peronów na terenie PKP Polskich Zakładów Kolejowych.</li>
        </ul>
        Wykonujemy prace na najwyższym poziomie,dając gwarancję na wykonywane
        roboty. Dzięki wykwalifikowanej kadrze pracowniczej gwarantujemy wysoką
        jakość świadczonych usług.
      </h4>
    </>
  );
};

export default Services;
